@import "bootstrap/scss/forms/form-select";

.form-select {
  --#{$prefix}form-select-color: #{$form-select-color};
  --#{$prefix}form-select-border-color: #{$form-select-border-color};
  --#{$prefix}form-select-hover-bg: #{$ds-background-input-hovered};
  --#{$prefix}form-select-focus-border-color: #{$form-select-focus-border-color};
  --#{$prefix}form-select-disabled-bg-image: #{escape-svg($form-select-disabled-indicator)};

  color: var(--#{$prefix}form-select-color);
  border-color: var(--#{$prefix}form-select-border-color);

  &:hover {
    background-color: var(--#{$prefix}form-select-hover-bg);
  }

  &:focus {
    border-color: var(--#{$prefix}form-select-focus-border-color);
  }

  &:disabled {
    --#{$prefix}form-select-bg-img: var(--#{$prefix}form-select-disabled-bg-image);
    pointer-events: none;
  }
}

@if $enable-dark-mode {
  @include color-mode(dark) {
    .form-select {
      &:disabled {
        --#{$prefix}form-select-bg-img: #{escape-svg($form-select-disabled-indicator-dark)};
      }
    }
  }
}
