.nav-tabs {
  --#{$prefix}nav-tabs-link-hover-border-color: #{$nav-tabs-border-hover-color};
  
  position: relative;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 100%;
    border-radius: 2px;
    background-color: $nav-tabs-border-color;
  }

  & > .nav-item {
    padding-left: var(--#{$prefix}nav-link-padding-x);
    padding-right: var(--#{$prefix}nav-link-padding-x);

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  .nav-link {
    --#{$prefix}nav-link-padding-x: 0;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      background-color: transparent;
      border-radius: 2px;
      @include transition($nav-link-transition);
    }

    &:hover::after {
      background-color: var(--#{$prefix}nav-tabs-link-hover-border-color);
    }

    &.active,
    .nav-item.show & {
      &::after {
        background-color: var(--#{$prefix}nav-tabs-link-active-color);
      }
    }
  }
}

///
/// Vertical tabs
/// 
.nav-tabs-vertical {
  --#{$prefix}nav-link-padding-x: #{$nav-link-padding-y}; 
  --#{$prefix}nav-link-padding-y: #{$nav-link-padding-x};

  flex-direction: column;

  &::before {
    width: 2px;
    height: 100%;
    left: 0;
    right: auto;
  }

  & > .nav-item {
    padding-left: 0;
    padding-right: 0;
    padding-top: var(--#{$prefix}nav-link-padding-y);
    padding-bottom: var(--#{$prefix}nav-link-padding-y);

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  .nav-link {
    --#{$prefix}nav-link-padding-y: 0;

    text-align: center;

    &::after {
      width: 2px;
      height: 100%;
      left: 0;
      right: auto;
    }
  }
}
