@import "bootstrap/scss/forms/form-range";

.form-range {
  --#{$prefix}form-range-track-fg: #{$ds-background-neutral-bold};
  --#{$prefix}form-range-track-hover-bg: #{$form-range-track-hover-bg};

  &::-webkit-slider-runnable-track {
    background-image: linear-gradient(var(--#{$prefix}form-range-track-fg), var(--#{$prefix}form-range-track-fg));
    background-size: var(--track-fg-width, 0) 100%;
    background-repeat: no-repeat;
    transition: $form-range-thumb-transition;
  }

  &::-moz-range-track {
    transition: $form-range-thumb-transition;
  }

  &::-moz-range-progress {
    width: 100%;
    height: $form-range-track-height;
    border-radius: $form-range-track-border-radius;
    transition: $form-range-thumb-transition;
    background-color: var(--track-fg);

    cursor: pointer;
  }

  &:hover:not(:disabled) {
    &::-webkit-slider-runnable-track {
      background-color: var(--#{$prefix}form-range-track-hover-bg);
    }

    &::-moz-range-track {
      background-color: var(--#{$prefix}form-range-track-hover-bg);
    }
  }

  &:disabled {
    opacity: $form-range-track-disabled-opacity;
  }
}