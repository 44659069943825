@import "bootstrap/scss/breadcrumb";

.breadcrumb {
  --#{$prefix}breadcrumb-link-color: #{$breadcrumb-link-color};
  --#{$prefix}breadcrumb-link-hover-color: #{$breadcrumb-link-color};

  a {
    --#{$prefix}link-color-rgb: var(--#{$prefix}breadcrumb-link-color);
    
    &:hover {
      --#{$prefix}link-color-rgb: var(--#{$prefix}breadcrumb-link-hover-color);
    }
  }

  .breadcrumb-item + .breadcrumb-item::before {
    float: none;
    min-width: $ds-space-100;
    text-align: center;
  }
}
