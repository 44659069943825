@import "bootstrap/scss/modal";

.modal-header + .modal-body {
  padding-top: 0;
  padding-bottom: 0;
}

.modal-dialog-scrollable {
  .modal-body {
    border-bottom: 2px solid $border-color;
  }
}
