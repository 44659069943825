@import "bootstrap/scss/progress";

.progress {
  position: relative;
}

.progress-indeterminate,
.progress-linear  {
  .progress-bar {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      background-color: inherit;
      animation: animation-indeterminate 2s ease infinite;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      background-color: inherit;
      animation: animation-indeterminate-short 2s ease 0.5s infinite;
    }
  }
}

@keyframes animation-indeterminate {
  0% {
    left: -5%;
    width: 5%;
  }

  100% {
    left: 130%;
    width: 100%;
  }
}

@keyframes animation-indeterminate-short {
  0% {
    left: -80%;
    width: 80%;
  }

  100% {
    left: 110%;
    width: 10%;
  }
}
