@import "bootstrap/scss/card";

.card-header {
  border-width: 0; // Remove border
}

.card-header-tabs {
  margin-right: calc(var(--#{$prefix}card-cap-padding-x) * -1);
  margin-bottom: calc(var(--#{$prefix}card-cap-padding-y) * -1);
  margin-left: calc(var(--#{$prefix}card-cap-padding-x) * -1);
  padding-left: var(--#{$prefix}card-cap-padding-x);
  padding-right: var(--#{$prefix}card-cap-padding-y);
}
