//** transition-property */
@each $key, $value in $transition-propertys {
  .transition#{if($key,"-" + #{$key},"")} {
    @if ($value) {
      @each $prop-name, $prop-value in $value {
        #{$prop-name}: #{$prop-value};
      }
    } @else {
      transition-property: none;
    }
  }
}

@each $value in $transition-delays {
  .delay-#{$value} {
    transition-delay: if($value==0, #{$value}s, #{$value}ms);
  }
}

@each $value in $transition-durations {
  .duration-#{$value} {
    transition-duration: if($value==0, #{$value}s, #{$value}ms);
  }
}

@each $key, $value in $transition-timing-functions {
  .#{$key} {
    transition-timing-function: #{$value};
  }
}
